// reset 
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #b4b4b4 !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #b4b4b4 !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #b4b4b4 !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #b4b4b4 !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ff7c7c;
}

@mixin btn {
    width: 129px;
    height: 45px;
    color: white;
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    margin: 0 auto;
    display: block;
    &:hover {
        color: white;
    }
}

@mixin btn-small {
    width: fit-content;
    height: 35px;
    color: white;
    border: 0px;
    border-radius: 0px;
    font-size: 18px;
    line-height: 1;
    &:hover {
        color: white;
    }
}
