@import 'responsive';
@import 'mixin';

$color-master: #fccd00;
$color-dark: #212121;
$color-master-btn: #dda800;
$color-dark-btn: #333;
$color-again-btn: #1890ff;
$color-border: rgba(#d8d8d8, 0.3);
$color-input: #f6f5f4;
$color-info: #ff7c7c;
$color-price: #7ab951;
$color-content-bg: #f6f5f4;
$color-text: #757575;;
$font-size-small: 12px;


.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}
.btn-login {
    background: $color-master;
    color: white!important;
    border: none;
    border-radius: 50px;
    &:hover {
        background: rgba($color-master,0.7);
        color: white;
    }
}
.btn-master {
    @include btn;
    background: $color-master-btn;
    &:hover {
        background: $color-dark-btn;
    }
    &:focus, &:active {
        color: white;
        background: $color-master-btn;
    }
    @include sm-screen {   
        @include btn-small;
    }
}
.btn-again {
    @include btn;
    background: $color-again-btn;
    &:hover {
        background: $color-dark-btn;
    }
    &:focus, &:active {
        color: white;
        background: $color-again-btn;
    }
    @include sm-screen {   
        @include btn-small;
    }
}
.btn-second {
    @include btn;
    background: $color-dark-btn;
    &:hover {
        background: $color-master-btn;
    }
    &:focus, &:active {
        color: white;
        background: $color-dark-btn;
    }
    @include sm-screen {   
        @include btn-small;
    }
}
.btn-back {
    @include btn;
    background: $color-dark-btn;
    line-height: 45px !important;
    &:hover {
        background: $color-master-btn;
    }
    &:focus, &:active {
        color: white;
        background: $color-dark-btn;
    }
    @include sm-screen {   
        @include btn-small;
        line-height: 35px !important;
    }
}
.main-layout {
    background: white;
    .content {
        .questionnaire-container {
            max-width: 951px;
            margin: 45px auto;
        }
        .ant-form {
                label{
                    @include md-screen {
                        font-size: 18px;
                    }
                }
            }
        .form {
            padding: 33px 0;
            margin: 20px 0;
            color: $color-text;
            background: $color-content-bg;
            @include sm-screen {
                padding: 33px 15px;
            }
            h1 {
                font-size: 21px;
                color: $color-text;
                @include md-screen {
                    font-size: 30px;
                }
            }
            p {
                font-size: 14px;
                color: $color-text;
                @include md-screen {
                    font-size: 16px;
                }
            }
            .formItem {
                max-width: 500px;
                margin: 24px auto;
                .ant-form-item-label {
                    padding: 0;
                    label {
                        font-size: 15px;
                        color: $color-text;
                        @include md-screen {
                            font-size: 18px;
                        }
                    }
                }
                .ant-input-number {
                    width: 100px;
                    background-color: transparent;
                    @include md-screen {
                        font-size: 18px;
                    }
                }
                .ant-input-number-handler-wrap, 
                .ant-input-number-handler-up,
                .ant-input-number-handler-down {
                    background-color: transparent;
                    border: 0;
                }
                .input-noBorder {
                    border: 0;
                }
                .input-email {
                    background-color: transparent;
                     @include md-screen {
                        font-size: 18px;
                    }
                }
                .ant-radio-checked {
                    .ant-radio-inner  {
                        border-color: $color-master-btn;
                    }
                    .ant-radio-inner::after {
                        background: $color-master-btn;
                    }
                }
            }
            .form-right {
                font-size: $font-size-small;
            }
            .resmed-link {
                display: block;
                color: $color-master-btn;
                border-color: $color-master-btn;
                margin: 15px 0;
                font-weight: bold;
                @include md-screen {
                    font-size: 18px;
                }
            }
            .result-info {
                font-size: 15px;
                margin: 30px 0;
                @include md-screen {
                    font-size: 18px;
                }
            }
            .ant-divider {
                border-color: #979797;
            }
            .result-text {
                font-size: 18px;
                margin: 20px 0;
            }
            .result-text-sm {
                font-size: 15px;
                @include md-screen {
                    font-size: 18px;
                }
            }
            .bmiValue {
                display: inline-block;
                font-size: 18px;
                border-bottom: 2px solid $color-text;
                padding: 0 20px;
                margin: 0 10px;
            }
            .risk-danger {
                color: #e24545;
            }
            .risk-safe {
                color: #4586e2;
            }
        }
    }
}